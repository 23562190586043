import React, { useEffect, useState } from 'react';
import WidgetBox, { WidgetTitle } from '../../../components/shared/widget-box';
import { getInstaPictures } from '../../../utils/instagram';
import { InstagramItem, InstagramWrap } from './instagram.stc';

const Instagram = ({ widgetStyle }) => {
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    getInstaPictures().then(instaPictures => {
      setPhotos(instaPictures.slice(0, 6));
    });
  }, []);

  return (
    <WidgetBox {...widgetStyle}>
      <WidgetTitle>Instagram</WidgetTitle>
      <InstagramWrap>
        {photos &&
          photos.map((photo, i) => {
            return (
              <InstagramItem key={`instagram-${i}`}>
                <img src={photo} alt="Instagram" />
              </InstagramItem>
            );
          })}
      </InstagramWrap>
    </WidgetBox>
  );
};

Instagram.defaultProps = {
  widgetStyle: {
    skin: 'primary'
  }
};

export default Instagram;
