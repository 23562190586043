import { graphql, useStaticQuery } from 'gatsby';
import moment from 'moment';
import React from 'react';
import Blog from '../../../components/blog/layout-three';
import WidgetBox, { WidgetTitle } from '../../../components/shared/widget-box';
import { RecentPostItem, RecentPostWrap } from './recent-post.stc';

const RecentPost = () => {
  const blogData = useStaticQuery(graphql`
    query RecentPostWidgetQuery {
      allMarkdownRemark(
        filter: {
          frontmatter: { format: { eq: "image" }, is_hidden: { ne: true } }
        }
        sort: { order: DESC, fields: frontmatter___date }
        limit: 4
      ) {
        edges {
          node {
            fields {
              slug
              dateSlug
            }
            frontmatter {
              date(formatString: "ll", locale: "pl")
              title
              format
              image {
                childImageSharp {
                  fixed(width: 78, height: 76, quality: 100) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const blogs = blogData.allMarkdownRemark.edges.filter(blog => {
    return moment().isAfter(blog.node.fields.dateSlug);
  });

  return (
    <WidgetBox>
      <WidgetTitle>Najnowsze wpisy</WidgetTitle>
      <RecentPostWrap>
        {blogs.map(blog => (
          <RecentPostItem key={blog.node.fields.slug}>
            <Blog
              content={{
                ...blog.node.fields,
                ...blog.node.frontmatter
              }}
            />
          </RecentPostItem>
        ))}
      </RecentPostWrap>
    </WidgetBox>
  );
};

export default RecentPost;
