import { navigate } from 'gatsby';
import React, { useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import Button from '../../../components/shared/button';
import Form, { Input } from '../../../components/shared/form';
import WidgetBox, { WidgetTitle } from '../../../components/shared/widget-box';
import { BtnWrap, FormWrapper } from './search.stc';

const SearchWidget = ({ widgetStyle, inputStyle }) => {
  const [value, setValue] = useState('');
  const onChangeHandler = event => setValue(event.target.value);
  const onSubmitHandler = event => {
    event.preventDefault();
    const query = (value || 'Iwona Libich')
      .toLowerCase()
      .trim()
      .replace(/[^\w ]/g, '');

    navigate(`/search?query=${query}`, { state: { query } });
  };
  return (
    <WidgetBox {...widgetStyle}>
      <WidgetTitle>Wyszukaj</WidgetTitle>
      <FormWrapper>
        <Form onSubmit={onSubmitHandler}>
          <Input
            {...inputStyle}
            onChange={onChangeHandler}
            type="text"
            name="search"
            id="search"
            placeholder="Podaj frazę..."
          />
          <BtnWrap>
            <Button type="submit" varient="iconButton" color="#63696a">
              <FiSearch />
            </Button>
          </BtnWrap>
        </Form>
      </FormWrapper>
    </WidgetBox>
  );
};

SearchWidget.defaultProps = {
  widgetStyle: {
    skin: 'primary'
  },
  inputStyle: {
    pr: '50px'
  }
};

export default SearchWidget;
