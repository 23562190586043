import styled from 'styled-components';
import { device } from '../../../theme';

export const InstagramWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
`;

export const InstagramItem = styled.figure`
  flex-basis: 50%;
  max-width: 50%;
  overflow: hidden;
  margin-bottom: 10px;
  border-radius: 8px;

  position: relative;

  ${device.xsmall} {
    flex-basis: 33.333%;
  }

  a {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-indent: -999999px;
  }

  img {
    border-radius: 5px;
    width: 90%;
    height: 90%;
  }
`;
